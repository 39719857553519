import { AfterContentChecked, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";

SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);
@Component({
  selector: 'app-salesreps',
  templateUrl: './salesreps.component.html',
  styleUrls: ['./salesreps.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalesrepsComponent implements OnInit, AfterContentChecked {
  @ViewChild('swiper') swiper: SwiperComponent
  @Input() team: any;

  constructor() { }
  ngAfterContentChecked(): void {
    if( this.swiper) {
      this.swiper.updateSwiper({})
    }
  }
  ngOnInit() {
    console.log(this.team);
    
  }

}


 