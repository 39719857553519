import { AfterContentChecked, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataService } from './services/data.service';
import SwiperCore, { Autoplay, EffectCoverflow, Navigation, SwiperOptions} from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent  {
 
  public msgSent: boolean = false
  public webData: any
  public name; phone; message; email: string 
  constructor(public dataService: DataService) {}


  ngOnInit() {
    this.dataService.getItemFromDatabase('website').subscribe(webData => {
      this.webData = webData[0]
      console.log(webData);
      
    })
  }


  sendMessage() {
    console.log(this.name, this.phone, this.message, this.email);
    
    const col = 'mail'
    this.dataService.addItemToDatabase('mail', {to: 'info@gudyfoods.com', message: {html: "<html>name :" + this.name + "<br>email :" + this.email + "<br>phone :" + this.phone + "<br>message :" + this.message+ "<br></html>", subject: 'contact from website' }}).then(() => {
      this.msgSent = true
    })
  } 
 

}
