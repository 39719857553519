import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { SalesrepsComponent } from './salesreps/salesreps.component';
import { enableIndexedDbPersistence } from '@firebase/firestore';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent, SalesrepsComponent],
  entryComponents: [],
  imports: [
    provideFirebaseApp(() => initializeApp( environment.firebase )),
    provideFirestore(() => {
      const firestore = getFirestore();
      // connectEmulator(firestore, 'localhost', 8080);
      enableIndexedDbPersistence(firestore);
      return firestore;
  }),
    BrowserModule, 
    FormsModule,
    SwiperModule,
    
    IonicModule.forRoot(), AppRoutingModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
