import { Injectable } from '@angular/core';
import { Firestore, collectionData, addDoc } from '@angular/fire/firestore';
import { collection, doc } from '@firebase/firestore';
// import { collectionData } from 'rxfire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(public firestore: Firestore) { }

  async addItemToDatabase(path, data) {
    return await addDoc(collection(this.firestore, path), data)

  }

  getItemFromDatabase(ref) {
    const collRef = collection(this.firestore, ref)
    return collectionData(collRef)
  }

  
}
