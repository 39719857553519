// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBnT3n22LM3cH4jpckIiIjPStNG7pH_LX4",
    authDomain: "gudyfoods2.firebaseapp.com",
    projectId: "gudyfoods2",
    storageBucket: "gudyfoods2.appspot.com",
    messagingSenderId: "288948147147",
    appId: "1:288948147147:web:ce8c50f16d4f3dc7d91c4e",
    measurementId: "G-P9N4P3T1N4"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
